<template>
  <div class="three-d-model">
    <div class="title-box">
      <div class="title-box__icon">
        <img src="../../../assets/icons/dashboard/3D Model.png" />
        <span>3D Model</span>
      </div>

      <el-button type="info"
        @click="toModelPage">View 3D</el-button>
    </div>

    <div class="three-d-model__content _flex _flex-justify__center">
      <img src="@/assets/icons/dashboard/tower.png" alt="" />
    </div>
  </div>
</template>

<script>
import { props } from './props'

export default {
  props,
  data() {
    return {
      towerModeUrl: '',
      towerCode: ''
    }
  },
  watch: {
    detail: {
      handler(val) {
        console.log('detail', val)
        if (val.towerModeUrl) this.towerModeUrl = val.towerModeUrl
        if (val.towerInfo) this.towerCode = val.towerInfo.code
      },
      deep: true
    }
  },
  methods: {
    toModelPage() {
      if (!this.towerModeUrl) return this.$message.warning('暂无模型')
      this.$router.push({ path: '/model' })
    }
  }
}
</script>

<style lang="scss" scoped>
.three-d-model__content {
  > img {
    width: 47%;
  }
}
</style>
